import { useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from "react";

type Post = {
  slug: string;
  title: string;
};

type PostListQueryResult = {
  allContentfulPost: {
    nodes: Post[];
  };
};

export const useSearchTitle = (q: string) => {
  const [result, setResult] = useState<Post[]>([]);

  const tempData: PostListQueryResult = useStaticQuery(graphql`
    query {
      allContentfulPost {
        nodes {
          slug
          title
        }
      }
    }
  `);

  const postData = tempData.allContentfulPost.nodes.map((node) => node);

  const search = () => {
    const searchWord = q.toLowerCase();
    const searchResult = postData.filter(({ title }) => {
      const s = escapeRegExp(searchWord);
      const reg = new RegExp(s, "i");

      return reg.test(title);
    });

    setResult(searchResult);
  };

  useEffect(() => {
    if (q === "") setResult([]);
    else search();
  }, [q]);

  return result;
};

// Unmatched ) in Javascript regular expression error occurs
// when some of your string contains ')'.
// You need to escape this.
//  Here is the function to do that:
const escapeRegExp = (str: string) =>
  str.replace(/[\\-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g, "\\$&");
