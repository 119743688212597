import * as React from "react";

export const Highlighter: React.FC<{
  title: string;
  q: string;
}> = ({ title, q }) => {
  const regex = new RegExp(q, "g");
  const highlight = (match: string) =>
    `<span style="background: rgba(255, 233, 0, 0.4); padding: 0.2rem 0">${match}</span>`;
  const highlightedTitle = title.replace(regex, highlight);

  return <span dangerouslySetInnerHTML={{ __html: highlightedTitle }} />;
};
