import { Link } from "gatsby";
import * as React from "react";

import { useSearchTitle } from "@/hooks/useSearchTitle";

import { Highlighter } from "@/components/Highlighter";
import { FeedIcon, SearchIcon, TwiIcon } from "@/components/icons";

export const useSearchModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const SearchModal: React.FC = () => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [q, setQ] = React.useState("");
    const searchResult = useSearchTitle(q);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
      setQ(e.target.value);

    React.useEffect(() => {
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    });

    return (
      <div
        onClick={close}
        className="fixed top-0 left-0 z-10 flex h-screen w-screen items-center justify-center bg-gray-600/60"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="z-20 flex h-5/6 w-5/6 max-w-2xl flex-col divide-y-2 rounded-lg bg-white shadow-xl md:max-h-96"
        >
          <div className="flex items-center gap-2 pl-4 text-gray-500">
            <SearchIcon />
            <input
              ref={inputRef}
              className="my-2 h-12 w-full p-4 text-lg focus:outline-none"
              onChange={handleChange}
              type="text"
              name="q"
              id="q"
              placeholder="タイトルを検索..."
            />
          </div>
          <ul className="grow list-none overflow-scroll px-4 pt-4">
            {searchResult.map((node) => {
              const link = "/post/" + node.slug;
              return (
                <li className="flex rounded py-2 px-1 transition-colors hover:bg-slate-300/40">
                  <Link
                    to={link}
                    key={node.slug}
                    onClick={close}
                    className="grow"
                  >
                    <Highlighter title={node.title} q={q} />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return { isOpen, open, SearchModal };
};

const HEADER_NAV_LINKS = [
  {
    label: "Post",
    path: "/post/",
  },
  {
    label: "Tag",
    path: "/tag/",
  },
];

const FOOTER_NAV_LINKS = [
  {
    label: "Top",
    path: "/",
  },
  {
    label: "About",
    path: "/about/",
  },
  {
    label: "Privacy",
    path: "/privacy/",
  },
  {
    label: "Post",
    path: "/post/",
  },
  {
    label: "Tag",
    path: "/tag/",
  },
];

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isOpen, open, SearchModal } = useSearchModal();

  return (
    <>
      {isOpen && <SearchModal />}
      <header className="px-4">
        <div className="mx-auto flex max-w-4xl justify-between border-b-2 pt-8 pb-4">
          <Link to="/">
            <h1 className="text-2xl font-bold">Logo</h1>
          </Link>
          <div className="flex items-end gap-6">
            <nav>
              <ul className="flex list-none gap-6">
                {HEADER_NAV_LINKS.map((item) => (
                  <li className="text-gray-500 transition-all hover:text-gray-700 hover:underline">
                    <Link to={item.path}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </nav>
            <button
              onClick={open}
              aria-label="search"
              className="pb-1 pr-1 text-gray-500 transition-all hover:text-gray-700"
            >
              <SearchIcon />
            </button>
          </div>
        </div>
      </header>
      <main className="py-8 px-6">
        <div className="mx-auto min-h-screen max-w-4xl">{children}</div>
      </main>
      <footer className="px-4">
        <div className="mx-auto flex max-w-4xl flex-col gap-8 border-t-2 pt-6 pb-12">
          <nav>
            <ul className="flex list-none flex-wrap gap-x-8 gap-y-2">
              {FOOTER_NAV_LINKS.map((item) => (
                <li className="text-gray-500 transition-all hover:text-gray-700 hover:underline">
                  <Link to={item.path}>{item.label}</Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="flex justify-between">
            <p className="text-gray-500">© your company inc.</p>
            <div className="flex gap-4">
              <a
                href="https://twitter.com/GatsbyJS"
                target="_blank"
                rel="noreferrer"
                aria-label="link to twitter of site owner"
              >
                <span className="text-2xl text-gray-500 transition-all hover:text-gray-700 hover:underline">
                  <TwiIcon />
                </span>
              </a>
              <Link to="/rss.xml" aria-label="link to rss.xml">
                <span className="text-2xl text-gray-500 transition-all hover:text-gray-700 hover:underline">
                  <FeedIcon />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
